import React from 'react'

const NavTop2Component = () => {
  return (
    <div>
        <a href='#services' className="nav-top-2">
            <span>
        Get 20% Off On All Services
            </span>
            <div>(*Limited Period Offer)</div>
        </a>
    </div>
  )
}

export default NavTop2Component