import React from "react";
import img from "../../media/images/Full-Body-Wax-Price.png";

const   LandingVideoComponent = () => {
  return (
    <div>
      <div className="landing-video">
        <div className="landing-video-main">
          <div className="landing-video-heading">
            Watch How We Deliver Waxing at Doorstep
          </div>
          <div className="home-package-head-icon">
            <img title="Mens body wax near me" src={img} alt="icon" />
          </div>

          <div className="landing-video-content">
            <iframe
              //   width="560"
              //   height="315"
              src="https://www.youtube.com/embed/rb1GMJ1GPJY?si=pEH6le0bBtbP-lBi"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerpolicy="strict-origin-when-cross-origin"
              allowfullscreen
            ></iframe>
          </div>
          <div id="why-us" className="landing-video-cta">
            <a
              href="#services"
              className="landing-page-button-web-yellow landing-page-button-black"
            >
              Book Your Waxing Now
            </a>

            <a
              href="#services"
              className="landing-page-button-mobile-yellow landing-page-button-black"
            >
              Book Your Waxing Now
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingVideoComponent;
