import React, { useState } from "react";

const FaqComponent = () => {
  const [show, setShow] = useState(false);


  return (
    <div>
      <div className="faq">
        <div>
          <div className="faq-heading">
            FREQUENTLY ASKED QUESTIONS{" "}
            <span
              onClick={(e) => {
                setShow(!show);
              }}
            >
              {
                show?
                <i className="fa-regular fa-rectangle-xmark"></i>
                :
                <i className="fa-regular fa-square-caret-left"></i>

              }
            </span>
          </div>

          {show ? (
            <>
              {/* <div className="faq-icon"><img src={img} alt="" /></div> */}

              <div className="faq-content">
                <div className="faq-content-list">
                  <div className="faq-content-ques">
                    Q1. What is Glazma Men’s Salon at Home?
                  </div>
                  <div className="faq-content-ans">
                    <span>Ans. </span>
                    Get Beauty Salon Services from certified and professional
                    beauticians in the comfort of your home or office or your
                    preferred location.
                  </div>
                </div>

                <div className="faq-content-list">
                  <div className="faq-content-ques">
                    Q2. What are the services included in the beauty and waxing
                    category?
                  </div>
                  <div className="faq-content-ans">
                    <span>Ans. </span>
                    Our Glazma beauty and waxing professionals provide various
                    services, including but not limited to groom package,
                    different types of hair services, Haircut, Hair colour, Full
                    body hair trimming, Full body shave, men waxing services, Full body detan, Body
                    massages, Full body waxing, DELICATE AREAS hair wax, Men’s body wax,
                    Men’s Beach-ready waxing, Facial and detan, Manicure, Pedicure,
                    De-tan treatment, Lighten the dark skin. If you have any
                    special requests, you can discuss them with our customer
                    care or professional.
                  </div>
                </div>

                <div className="faq-content-list">
                  <div className="faq-content-ques">
                    Q3. Which brands products do Glazma Men’s Salon Wax & Beauty
                    salons use?
                  </div>
                  <div className="faq-content-ans">
                    <span>Ans. </span>Our best men’s waxing & beauty salon
                    expert generally, use the best, safe, and hygienic products
                    depending upon your skin types. Some may even offer certain
                    brands that you can choose from while book men’s grooming
                    package services. Brands which are most famous are like
                    -Aroma, Nature's facial, O3+ facial, Lotus facial, Shahnaz
                    facial, Aloe vera, O3+ body wax, Raaga wax, Rica body wax,
                    body wax, Naturence, Serum, Lacto Pack, L'Oreal, and Plum
                    etc.
                  </div>
                </div>

                <div className="faq-content-list">
                  <div className="faq-content-ques">
                    Q4. How Excellent are Glazma -Men’s Beauty & Wax Expert?
                  </div>
                  <div className="faq-content-ans">
                    <span>Ans. </span>Glazma Men’s Salon all beauticians are
                    certified, experienced and well trained and have a minimum
                    of 5 years of work experience in beauty and salon industry.
                    We also organize training programs for all our beauticians
                    to enhance their skill and knowledge time to time.
                  </div>
                </div>

                <div className="faq-content-list">
                  <div className="faq-content-ques">
                    Q5. Can I get assurance to trust your hair stylists,
                    beauticians and waxing experts when I am alone at home? Are
                    they verified & professionals?
                  </div>
                  <div className="faq-content-ans">
                    <span>Ans. </span>Yes of course, you can trust Glazma -Men's
                    Salon hair stylists, beauticians and waxing experts because
                    all of them are company payroll not unknown vendors, our all
                    experts certified and professional and they have passed the
                    police verification process also. The client security is
                    main priority for us.
                  </div>
                </div>

                <div className="faq-content-list">
                  <div className="faq-content-ques">
                    Q6. Which Locations are you covering?
                  </div>
                  <div className="faq-content-ans">
                    <span>Ans. </span>Glazma Men’s Salon at Home offer beauty
                    and waxing salon services for men at home in Noida, Greater
                    Noida, Noida Extension, Indirapuram, Ghaziabad, male waxing gurgaon,
                    Faridabad, and Men waxing Delhi NCR.
                  </div>
                </div>

                <div className="faq-content-list">
                  <div className="faq-content-ques">
                    Q7. Do Glazma Men’s Salon & Waxing artist near me accept
                    online payments?
                  </div>
                  <div className="faq-content-ans">
                    <span>Ans. </span>Yes, Glazma Men's Beauty Parlour Services
                    at home in Noida, Greater
                    Noida, Noida Extension, Indirapuram, Ghaziabad, Gurugram,
                    Faridabad, and male waxing delhi NCR provide multiple payment options including
                    online payment (Paytm, phone pay, upi and we accept cash
                    also.
                  </div>
                </div>

                <div className="faq-content-list">
                  <div className="faq-content-ques">
                    Q8. How much time is taken to confirm my salon services
                    appointment?
                  </div>
                  <div className="faq-content-ans">
                    <span>Ans. </span>For any customer's first booking with us,
                    Glazma Men’s Salon take up to approx. half an hour to verify
                    and confirm the appointment. For customers who have availed
                    at least 1 service with us before, the appointment is
                    confirmed immediately.
                  </div>
                </div>

                <div className="faq-content-list">
                  <div className="faq-content-ques">
                    Q9. How to Book Call Appointment?
                  </div>
                  <div className="faq-content-ans">
                    <span>Ans. </span>Open the www.glazma.com website and browse
                    through the categories of services provided by Glazma Men’s
                    Beauty Salon at home. Click the service you need, choose
                    your date and time preference. You will be asked to make a
                    payment for the service, you will get service at your
                    preferred location.
                  </div>
                </div>

                <div className="faq-content-list">
                  <div className="faq-content-ques">
                    Q10. How to get my appointment confirmation?
                  </div>
                  <div className="faq-content-ans">
                    <span>Ans. </span>You can always find the appointment
                    receipt in email as well as WhatsApp message. In-case you
                    have not provided the correct email id or mobile no, you
                    need to contact us via WhatsApp or call at: 9658-153-153.
                  </div>
                </div>

                <div className="faq-content-list">
                  <div className="faq-content-ques">
                    Q11. Should I need to make advance booking with Glazma best
                    beauty parlour near me?
                  </div>
                  <div className="faq-content-ans">
                    <span>Ans. </span>Yes, you have to Book Call Appointment at
                    our website www.glazma.com or call/whatsapp at 9658-153153.
                    It is recommended to take an appointment at least a few
                    hours before Men's beauty and waxing services required to
                    you.
                  </div>
                </div>

                <div className="faq-content-list">
                  <div className="faq-content-ques">
                    Q12. Do Glazma Men's best salon providing male body waxing
                    and beauty parlour services at doorstep offer combo deals?
                  </div>
                  <div className="faq-content-ans">
                    <span>Ans. </span>Yes, Glazma Men's Body Waxing Salon
                    various offer package deals and great discounts. which will
                    be to you once our beauty expert visits your
                    location.
                  </div>
                </div>

                <div className="faq-content-list">
                  <div className="faq-content-ques">
                    Q13. What all services are included in pre wedding groom
                    packages offered by Glazma Men's Salon?
                  </div>
                  <div className="faq-content-ans">
                    <span>Ans. </span>Pre wedding groom packages include hair
                    styling and beard styling of the groom to suit her makeup,
                    draping of the wedding dress, and regular makeup of the
                    groom and the male guests. While body scrubs and polish,
                    facial, Dtan, body waxing, DELICATE AREAS hair waxing, full body
                    pack, manicure and pedicure, hair spa, a relaxing massage,
                    and lightening the dark skin are part of exclusive groom
                    package. If you have any specific requirements, you can
                    discuss those with the beauty expert in advance and he can
                    customise your package as per your needs.
                  </div>
                </div>

                <div className="faq-content-list">
                  <div className="faq-content-ques">
                    Q14. What is men's hair waxing? What are the different
                    styles of waxing offered by Glazma -men's waxing salon at
                    home?
                  </div>
                  <div className="faq-content-ans">
                    <span>Ans. </span>The process of removing unwanted hair from
                    body parts applying wax and peeling off the wax and hairs
                    together is called waxing. There are different types of
                    waxing styles offer by men’s waxing service salon at home.
                    Normal waxing, DELICATE AREAS hair waxing, Full Beach-ready waxing, French
                    waxing, body waxing, body Beach-ready waxing, Hollywood style
                    waxing. While Men’s full body waxing includes: Full legs
                    wax, DELICATE AREAS hair wax, Full arm wax, Chest wax,
                    Stomach wax, Full back wax, Shoulder wax, Underarms wax. All
                    of these areas are waxed accordingly and other areas can be
                    added with an additional price. We provide men’s Beach-ready
                    waxing, Full body waxing, Men’s DELICATE AREAS hair removal salon,
                    body waxing in Noida, Greater Noida, Ghaziabad, Faridabad,
                    Gurgaon and Delhi -NCR.
                  </div>
                </div>

                <div className="faq-content-list">
                  <div className="faq-content-ques">
                    Q15. Do You Offer Outstation Parlour Services?
                  </div>
                  <div className="faq-content-ans">
                    <span>Ans. </span>Yes, Glazma -Men's Beauty and Waxing Salon
                    offer outstation parlour services also.
                  </div>
                </div>
              </div>
            </>
          ) : (
            ""
          )}

                  <a href="#services" className="landing-page-button-web-yellow">
                  Book Your Waxing Now
                  </a>

                  
                  <a href="#services" className="landing-page-button-mobile-yellow">
                  Book Your Waxing Now
                  </a>
          
        </div>
      </div>
    </div>
  );
};

export default FaqComponent;
