import React, { useRef } from "react";
import logo from "../../media/images/glazma-logo.png";
import { Link } from "react-router-dom";

const Navbar = () => {
  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  return (
    <div>
    <div className="navbar-main">
    </div>

    <div className="navbar">
      <div className="nav-logo">
        <Link title="Waxing Near Me" to="/">
          <img src={logo} alt="logo" title="Men's salon at home" />
        </Link>
      </div>

      <div className="nav-menu" ref={navRef}>
        <Link title="Male Waxing Near Me" to="/">HOME</Link>
        <Link title="Waxing Near Me" to="/our-prices.php">PRICES</Link>
        <Link title="Male Hair Removal Near Me" to="/package">PACKAGES</Link>
        <Link title="Body Waxing Near Me" to="/contact">CONTACT US</Link>
        <div className="nav-drop">
          <span>
            COMPANY <i className="fa-solid fa-caret-down"></i>
          </span>
          <div className="nav-drop-content">
            <div className="nav-d-c-link">
              <Link title="Wax Places Near Me" to="/about.php">ABOUT US</Link>
              <Link title="Leg Waxing Near Me" to="/blog">BLOG</Link>
              <Link title="Body Waxing Near Me" to="/gallery.php">GALLERY</Link>
              <Link title="Full Body Wax Near Me" to="/franchise">FRANCHISE</Link>
            </div>
          </div>
        </div>

        <button className="nav-btn nav-close-btn" onClick={showNavbar}>
          <i className="fa-solid fa-circle-xmark"></i>
        </button>
      </div>
      <button className="nav-btn menu-btn landing-nav-col">
              <Link to="tel:+919658153153" target="blank"><i class="fa-solid fa-phone"></i>+91 9658153153</Link>
              <i className="fa-solid fa-bars"  onClick={showNavbar}></i>
            </button>
  </div>
  </div>
  )
}

export default Navbar