import React from 'react'
import { Link } from 'react-router-dom'
import logo from "../../media/images/glazma-logo-W.png";


const LandingFooterComponent = () => {
  return (
    
    <div className="landing-footer">
  <div>

        <div className="landing-footer-cols">
          <div className="landing-footer-col-1">
            <div  className="landing-footer-col-1-logo">
             
              <div className="footer-logo"><img src={logo} alt="logo" /></div>
            </div>

            <div className="landing-footer-col-1-footer-icon">
            
              <a href='#how-we-deliver'
              >
                <i className="fa-brands fa-facebook"></i>
              </a>
              <a href='#how-we-deliver'
              >
                <i className="fa-brands fa-instagram"></i>
              </a>
              <a href='#how-we-deliver'
              >
                <i className="fa-brands fa-x-twitter"></i>
              </a>
             
              <a href='#how-we-deliver'
              >
                <i className="fa-brands fa-youtube"></i>
              </a>
              <a href='#how-we-deliver'
              >
                <i className="fa-brands fa-pinterest"></i>
              </a>
            </div>
          </div>

          <div className="landing-footer-col-2">
            <div className="landing-footer-col-2-col">
              <div className="landing-footer-col-2-col-heading">
                {/* COMPANY */}
                QUICK LINKS
              </div>
              <div className="landing-footer-col-2-col-points">
                <a href='#services' >Our Prices</a>
                <a href='#packages' >Our Packages</a>
                <a href='#why-us'>Why Us</a>
                <a href='#testimonial' >Testimonial</a>
                {/* <a href='#faqs'  >FAQs</a> */}
                <Link target='_blank' to="/privacy_policy"  >Privacy Policy</Link>
                
              </div>
            </div>




            <div className="landing-footer-col-2-col">
              <div className="landing-footer-col-2-col-heading">
              CORPORATE OFFICE
              </div>
              <div className="landing-footer-col-2-col-points">
                <div>
                  <span>
                    <i className="fa-solid fa-location-arrow"></i>
                  </span>
                  {/* 210, 2nd Floor, Ocean Plaza,  */}
                  520, 5th Floor, Ocean Complex,<br />
                  Sector-18, Noida - 201301
                </div>
                <Link  target="_blank" to="tel:01204116153">
                  <span>
                    <i className="fa-solid fa-mobile-screen-button"></i>
                  </span>{" "}
                  0120 4116153
                </Link>
                <div  target="_blank" >
                  <span>
                    <i className="fa-solid fa-envelope"></i>{" "}
                  </span>
                  info@glazma.com
                </div>
                <Link target="_blank" to="https://wa.me/+919658153153?text=Hello ! I'm Interested in Doorstep Men Grooming Service. Please contact me.">
                  <span>
                    <i className="fa-brands fa-whatsapp"></i>
                  </span>{" "}
                  +91 9658-153-153
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="landing-footer-cc">
          Copyright <i className="fa-regular fa-copyright"></i> 2019, GLAZMA.
          All Rights Reserved.
        </div>
      </div>
        
    </div>
  )
}

export default LandingFooterComponent