import React from 'react'
import { Link } from 'react-router-dom'
import logo from "../../media/images/glazma-logo-W.png";


const FooterComponent = () => {
  return (
    
    <div className="landing-footer">
  <div>

        <div className="landing-footer-cols">
          <div className="landing-footer-col-1">
            <Link title="Ear Waxing Near Me" to="/" className="landing-footer-col-1-logo">
              {/* <div className="landing-footer-logo-icon">
                <img src={redLogo} alt="logo" />
              </div> */}


              {/* <div className="landing-footer-logo-text">
                <div className="landing-footer-logo-text-1">
                  FLUFFY<span>&nbsp;TOUCH</span>
                </div>
                <div className="landing-footer-logo-text-2">
                  your pet deserve the best
                </div>
              </div> */}
              <div className="footer-logo"><img title='Hair expert near me' src={logo} alt="logo" /></div>
            </Link>

            <div className="landing-footer-col-1-footer-icon">
              <Link title="Underarm Waxing Near Me" to="https://www.facebook.com/glazmaofficial/"
                target="_blank"
              >
                <i className="fa-brands fa-facebook"></i>
              </Link>
              <Link title="Cheap Waxing Near Me" to="https://www.instagram.com/glazmaofficial/"
                target="_blank"
              >
                <i className="fa-brands fa-instagram"></i>
              </Link>
              <Link title="Wax Spa Near Me" to="https://twitter.com/i/flow/login?redirect_after_login=%2Fglazmaofficial"
                target="_blank"
              >
                <i className="fa-brands fa-x-twitter"></i>
              </Link>
             
              <Link title="Manzilian Wax" to="https://www.youtube.com/@glazma"
                target="_blank"
              >
                <i className="fa-brands fa-youtube"></i>
              </Link>
              <Link title="Bikini Line Hair Removal" to="https://in.pinterest.com/glazmaofficial/"
                target="_blank"
              >
                <i className="fa-brands fa-pinterest"></i>
              </Link>
            </div>
          </div>

          <div className="landing-footer-col-2">
            <div className="landing-footer-col-2-col">
              <div className="landing-footer-col-2-col-heading">
                {/* COMPANY */}
                QUICK LINKS
              </div>
              <div className="landing-footer-col-2-col-points">
                <Link title="Men's Back Waxing Near Me" to="/our-prices.php">Prices</Link>
                <Link title="Pubic Hair Removal For Men" to="/package">Packages</Link>
                <Link title="Best Pubic Hair Removal" to="/about.php">About Us</Link>
                <Link title="Wax Hair Removal Near Me" to="/contact">Contact Us</Link>
                <Link title="Male Pubic Hair Removal Salon Near Me" to="/faqs">FAQs</Link>
                <Link title="Best Way To Remove Bikini Hair" to="/privacy_policy">Privacy Policy</Link>
                <Link title="Bikini Hair Removal At Home" to="/franchise">Franchise</Link>
              </div>
            </div>

            <div className="landing-footer-col-2-col">
              <div className="landing-footer-col-2-col-heading">EXPLORE</div>
              <div className="landing-footer-col-2-col-points">
              




                <Link title="Bikini Area Hair Removal"  to='/men-wedding-grooming-packages'>Wedding Packages</Link>
                <Link title="Men's Waxing Near Me" target='_blank' to='/meeting-refresh'>Meeting Refresh</Link>
                <Link title="Best Way To Get Rid Of Pubic Hair" target='_blank' to='/lighten-the-dark-skin'>Lighten The Dark</Link>
                <Link title="Wax Spa Near Me" target='blank' to='/full-body-wax'>Full Body Wax</Link>
                <Link title="Best Way To Remove Bikini Hair" target='_blank' to='/pubic-hair-wax'>Pubic Hair Wax</Link>
                <Link title="Bikini Hair Removal At Home" target='_blank' to='/full-body-polishing'>Full Body Polishing</Link>
                <Link title="Brazilian Sugaring Near Me" target='_blank' to='/full-body-scrub'>Full Body Scrub</Link>
              </div>
            </div>

            <div className="landing-footer-col-2-col">
              <div className="landing-footer-col-2-col-heading">
              CORPORATE OFFICE
              </div>
              <div className="landing-footer-col-2-col-points">
                <div>
                  <span>
                    <i className="fa-solid fa-location-arrow"></i>
                  </span>
                  {/* 210, 2nd Floor, Ocean Plaza,  */}
                  520, 5th Floor, Ocean Complex,<br />
                  Sector-18, Noida - 201301
                </div>
                <Link title="Wax Appointment Near Me" target="_blank" to="tel:01204116153">
                  <span>
                    <i className="fa-solid fa-mobile-screen-button"></i>
                  </span>{" "}
                  0120 4116153
                </Link>
                <Link title="Best Wax For Bikini Area" target="_blank" to="mailto:info@glazma.com">
                  <span>
                    <i className="fa-solid fa-envelope"></i>{" "}
                  </span>
                  info@glazma.com
                </Link>
                <Link title="Manzilian Near Me" target="_blank" to="https://wa.me/+919658153153?text=Hello ! I'm Interested in Doorstep Men Grooming Service. Please contact me.">
                  <span>
                    <i className="fa-brands fa-whatsapp"></i>
                  </span>{" "}
                  +91 9658-153-153
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="landing-footer-cc">
          Copyright <i className="fa-regular fa-copyright"></i> 2019, GLAZMA.
          All Rights Reserved.
        </div>
      </div>
        
    </div>
  )
}

export default FooterComponent