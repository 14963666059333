import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './App.css';
import "./css/user/Navbar.css"
import "./css/user/HomePage.css"
import "./css/user/HeaderComponent.css"
import "./css/user/FaqComponent.css"
import "./css/user/NumberIncrement.css"
import "./css/user/Testimonial.css"
import "./css/user/WhyUs.css"
import "./css/user/Location.css"
import "./css/user/Footer.css"
import "./css/user/Button.css"
import "./css/user/Animations.css"
import "./css/user/ContactComponent.css"
import "./css/user/Breadcrumb.css"
import "./css/user/PrivacyPolicy.css"
import "./css/user/Franchise.css"
import "./css/user/AboutPage.css"
import "./css/user/GalleryPage.css"
import "./css/user/ErrorPage.css"
import "./css/user/PricePage.css"
import "./css/user/BlogPage.css"
import "./css/user/PopupOffer.css"
import "./css/user/ServiceComponent.css"
import "./css/user/PackageBookingForm.css"
import "./css/user/Pagination.css"
import "./css/user/FaqPage.css"
import "./css/user/Loading.css"
import "./css/user/Cart.css"
import "./css/user/LocationPageComponent.css"
import "./css/user/ClientSaysComponent.css"
import "./css/user/LandingPage.css"
import "./css/price/GMS_PriceList.css"
import "./css/price/GSM_PriceDetails.css"

import "./css/user/ConfirmBookingForm.css"
import "./css/blog/SingleBlog.css"
import "./css/admin/AdminPage.css"
import "./css/admin/AdminNav.css"
import "./css/admin/AdminHome.css"
import "./css/admin/AdminBooking.css"
import "./css/admin/AdminUpdatePopup.css"
import "./css/cart/ViewCartComponent.css"
// import "./css/service/ServicePage.css"
// import "./css/user/Footer copy.css"
// import "./css/other/Other.css"

import HomePage from './pages/user/HomePage';
import TopButton from './components/user/TopButton';
import PackagesPage from './pages/user/PackagesPage';
import ContactPage from './pages/user/ContactPage';
import PrivacyPolicyPage from './pages/user/PrivacyPolicyPage';
import FranchisePage from './pages/user/FranchisePage';
import ScrollToTop from './components/user/ScrollToTop';
import AboutPage from './pages/user/AboutPage';
import GalleryPage from './pages/user/GalleryPage';
import ErrorPage from './pages/user/ErrorPage';
import PricePage from './pages/user/PricePage';
import TrimYourPubicHair from './pages/blog/TrimYourPubicHair';
import ReasonsWhyYouNeedToManscaped from './pages/blog/ReasonsWhyYouNeedToManscaped';
import GroomingTips from './pages/blog/GroomingTips';
import FaceAndBeardGroomingTips from './pages/blog/FaceAndBeardGroomingTips';
import WhyIsItImportantToTrimPubicHair from './pages/blog/WhyIsItImportantToTrimPubicHair';
import LightenTheDarkSkin from './pages/blog/LightenTheDarkSkin';
import MensPreWeddingGroom from './pages/blog/MensPreWeddingGroom';
import MensFacial from './pages/blog/MensFacial';
import FullBodyWax from './pages/blog/FullBodyWax';
import PubicOrBrazilianHairWaxing from './pages/blog/PubicOrBrazilianHairWaxing';
import BrazilianWaxing from './pages/blog/BrazilianWaxing';
import FullBodyScrub from './pages/blog/FullBodyScrub';
import FullBodyPolishing from './pages/blog/FullBodyPolishing';
import ManicureAndPedicure from './pages/blog/ManicureAndPedicure';
import BodyMassagesTherapyAtHome from './pages/blog/BodyMassagesTherapyAtHome';
import HairColourAtHome from './pages/blog/HairColourAtHome';
import MensHairSpa from './pages/blog/MensHairSpa';
import MeetingRefresh from './pages/blog/MeetingRefresh';
import EventPartyGrooming from './pages/blog/EventPartyGrooming';
import MensPreWeddinGroomingServicesNearMe from './pages/blog/MensPreWeddinGroomingServicesNearMe';
import HoneymoonSpecialGrooming from './pages/blog/HoneymoonSpecialGrooming';
import WeddingGroomPackagesPage from './pages/user/WeddingGroomPackagesPage';
import FaqPage from './pages/user/FaqPage';
import CartComponent from './components/user/CartComponent';
import ConfirmBookingPage from './pages/user/ConfirmBookingPage';
import ConfirmQueryPage from './pages/user/ConfirmQueryPage';
import AdminLogin from './pages/admin/AdminLogin';
import AdminHomePage from './pages/admin/AdminHomePage';
import AdminOrdersPage from './pages/admin/AdminOrdersPage';
import AdminInquiryPage from './pages/admin/AdminInquiryPage';
import AdminContactUs from './pages/admin/AdminContactUs';
import LocationPage from './pages/user/LocationPage';
import BlogPage from './pages/user/BlogPage';
import TreatCrackedHeels from './pages/blog/TreatCrackedHeels';
import TreatCutsAndSrapes from './pages/blog/TreatCutsAndSrapes';
import ProfessionalManicureAtHome from './pages/blog/ProfessionalManicureAtHome';
import ShaveWithoutIrritation from './pages/blog/ShaveWithoutIrritation';
import AchieveGlowingSkinNaturally from './pages/blog/AchieveGlowingSkinNaturally';
import AchieveHealthyHydratedLips from './pages/blog/AchieveHealthyHydratedLips';
import SalonQualityNailArt from './pages/blog/SalonQualityNailArt';
import SoftSmoothHands from './pages/blog/SoftSmoothHands';
import BlackheadsSafely from './pages/blog/BlackheadsSafely';
import HairThicknessAndVolume from './pages/blog/HairThicknessAndVolume';
import MaintainSmoothSkin from './pages/blog/MaintainSmoothSkin';
import MinimizePainDuringHairRemoval from './pages/blog/MinimizePainDuringHairRemoval';
import AthletesFoot from './pages/blog/AthletesFoot';
import NailsFungalInfections from './pages/blog/NailsFungalInfections';
import ContactButtons from './components/user/ContactButtons';
import PreventIngrownHairs from './pages/blog/PreventIngrownHairs';
import IPreventIngrownHair from './pages/blog/IPreventIngrownHair';
import AgingOnMyHands from './pages/blog/AgingOnMyHands';
import ProtectHairFromChlorineDamage from './pages/blog/ProtectHairFromChlorineDamage';
import ReduceAcneAndBreakouts from './pages/blog/ReduceAcneAndBreakouts';
import TreatDryBrittleNails from './pages/blog/TreatDryBrittleNails';
import FeetBetweenPedicure from './pages/blog/FeetBetweenPedicure';
import HandsAfterGardening from './pages/blog/HandsAfterGardening';
import SkinAfterLaserHairRemoval from './pages/blog/SkinAfterLaserHairRemoval';
import HairRemovalTreatments from './pages/blog/HairRemovalTreatments';
import RightCleanserForSkin from './pages/blog/RightCleanserForSkin';
import RightExfoliatorForPreWaxing from './pages/blog/RightExfoliatorForPreWaxing';
import RightHandCream from './pages/blog/RightHandCream';
import RightHandSoap from './pages/blog/RightHandSoap';
import RightMoisturizer from './pages/blog/RightMoisturizer';
import RightNailShape from './pages/blog/RightNailShape';
import ChooseRightRazor from './pages/blog/ChooseRightRazor';
import RightShampooAndConditioner from './pages/blog/RightShampooAndConditioner';
import ChooseRightShoes from './pages/blog/ChooseRightShoes';
import PermanentHairRemoval from './pages/blog/PermanentHairRemoval';
import NighttimeSkincareRoutine from './pages/blog/NighttimeSkincareRoutine';
import VibrantHairColor from './pages/blog/VibrantHairColor';
import PrepareForLaserHairRemoval from './pages/blog/PrepareForLaserHairRemoval';
import PrepareSkinForWaxing from './pages/blog/PrepareSkinForWaxing';
import LipInfections from './pages/blog/LipInfections';
import PreventNailPolish from './pages/blog/PreventNailPolish';
import PrematureAging from './pages/blog/PrematureAging';
import SplitEnds from './pages/blog/SplitEnds';
import CareForSensitiveSkin from './pages/blog/CareForSensitiveSkin';
import RemoveGelPolish from './pages/blog/RemoveGelPolish';
import SafelyRemoveCalluses from './pages/blog/SafelyRemoveCalluses';
import SootheSunburnedSkin from './pages/blog/SootheSunburnedSkin';
import ExfoliateSkin from './pages/blog/ExfoliateSkin';
import WashMyHair from './pages/blog/WashMyHair';
import MaleBrazilianWax from './pages/blog/MaleBrazilianWax';
import MenBrazilianWax from './pages/blog/MenBrazilianWax';
import MenLikeBrazilianWax from './pages/blog/MenLikeBrazilianWax';
import FullBodyWaxing from './pages/blog/FullBodyWaxing';
import ManicuresPedicures from './pages/blog/ManicuresPedicures';
import FacialsForMen from './pages/blog/FacialsForMen';
import MaleIntimateWaxing from './pages/blog/MaleIntimateWaxing';
import TypesOfWaxing from './pages/blog/TypesOfWaxing';
import PubicHairRemoval from './pages/blog/PubicHairRemoval';
import ShavingVsWaxing from './pages/blog/ShavingVsWaxing';
import PubicHairRemovalMethods from './pages/blog/PubicHairRemovalMethods';
import ImportanceOfBodyPolish from './pages/blog/ImportanceOfBodyPolish';
import LandingPage from './pages/user/LandingPage';
import OtherPage from './pages/user/OtherPage';
import { Helmet } from 'react-helmet';
import LandingPageNcr from './pages/user/LandingPageNcr';
import GMS_PriceList from './components/price/GMS_PriceList';
import ViewCartComponent from './components/cart/ViewCartComponent';
import WaxingServicesDoorstep from './pages/blog/WaxingServicesDoorstep';

const App = () => {
  return (
    <div>


      
<Helmet>
          {/* {window.location.pathname === "/delhi"?"":""} */}
<meta
      name="keywords"
      content="Men's salon at home, Mens grooming doorstep, Mens parlour in delhi, Mens beauty parlour in noida, Groom Makeup, corporate barber, Mens beauty services at home, best Makeup artist, Best Mens salon in delhi, Best Mens salon in Noida , Mens waxing near me, Men's waxing at home, Mens waxing cost, Mens waxing price, Mens waxing in Delhi, Mens waxing in Noida, Mens waxing in Ghaziabad, Mens waxing in Greater noida, Mens waxing in Gurgaon, Mens waxing in Noida Extension, Mens body wax near me, Best body wax artist near me, body wax expert at home, body wax expert near me, Mens waxing expert near me, Beauty treatment at home, 
      Parlour home services, Hair stylist at home, Beauty parlour services at doorstep, Super salon at home, Body polish near me, Body polish at home, Body polish expert at home, Hair color at home, Hair expert at home, Hair expert near me, Beard designer expert at home, Hair and beard expert at doorstep, Designer hair and beard cut expert at doorstep"
    />
    
          
      </Helmet>
      
      <BrowserRouter>
      <ScrollToTop/>

      <CartComponent/>
 
        <Routes>
          <Route path='/' element={<HomePage />}></Route>
          <Route path='/package' element={<PackagesPage />}></Route>
          <Route path='/package.php' element={<PackagesPage />}></Route>
          <Route path='/contact' element={<ContactPage />}></Route>
          <Route path='/contact.php' element={<ContactPage />}></Route>
          <Route path='/privacy_policy' element={<PrivacyPolicyPage />}></Route>
          <Route path='/privacy_Policy.php' element={<PrivacyPolicyPage />}></Route>
          <Route path='/franchise' element={<FranchisePage />}></Route>
          <Route path='/franchise.php' element={<FranchisePage />}></Route>
          <Route path='/about.php' element={<AboutPage />}></Route>
          <Route path='/gallery.php' element={<GalleryPage />}></Route>
          <Route path='/our-prices.php' element={<PricePage />}></Route>
          <Route path='/our-prices' element={<PricePage />}></Route>
          {/* <Route path='/blog' element={<BlogPage />}></Route> */}
          <Route path='/men-wedding-grooming-packages' element={<WeddingGroomPackagesPage />}></Route>
          <Route path='/faqs' element={<FaqPage />}></Route>
          <Route path='/booking-confirm' element={<ConfirmBookingPage />}></Route>
          <Route path='/thank-you' element={<ConfirmBookingPage />}></Route>
          <Route path='/contact-confirm' element={<ConfirmQueryPage />}></Route>

          <Route path='/*' element={<ErrorPage />}></Route>
          {/* <Route path='/other' element={<OtherPage />}></Route> */}
          {/* <Route path='/other1' element={<Other1Page />}></Route> */}



{/* ---------------------------------- landing page ------------------------------------- */}
          {/* <Route path='/delhi-ncr' element={<LandingPage location="delhi NCR" />}></Route>
          <Route path='/delhi' element={<LandingPage location="delhi" />}></Route>
          <Route path='/noida' element={<LandingPage location="Noida" />}></Route>
          <Route path='/greater-noida' element={<LandingPage location="Greater Noida" />}></Route>
          <Route path='/gurgaon' element={<LandingPage location="Gurgaon" />}></Route>
          <Route path='/ghaziabad' element={<LandingPage location="Ghaziabad" />}></Route>
          <Route path='/faridabad' element={<LandingPage location="Faridabad" />}></Route> */}

          <Route path='/mens-grooming-home/delhi-ncr' element={<LandingPageNcr location="delhi-ncr" />}></Route>

          <Route path='/mens-grooming-home/delhi' element={<LandingPage location="delhi" />}></Route>
          <Route path='/mens-grooming-home/noida' element={<LandingPage location="Noida" />}></Route>
          <Route path='/mens-grooming-home/greater-noida' element={<LandingPage location="Greater Noida" />}></Route>
          <Route path='/mens-grooming-home/gurgaon' element={<LandingPage location="Gurgaon" />}></Route>
          <Route path='/mens-grooming-home/ghaziabad' element={<LandingPage location="Ghaziabad" />}></Route>
          <Route path='/mens-grooming-home/faridabad' element={<LandingPage location="Faridabad" />}></Route>

{/* ---------------------------------- Locations ------------------------------------- */}

          <Route path='/salon-at-home/delhi' element={<LocationPage location="Delhi" />}></Route>
          <Route path='/salon-at-home/noida' element={<LocationPage location="noida"/>}></Route>
          <Route path='/salon-at-home/greater-noida' element={<LocationPage location="greater noida"/>}></Route>
          <Route path='/salon-at-home/gurgaon' element={<LocationPage location="gurgaon"/>}></Route>
          <Route path='/salon-at-home/ghaziabad' element={<LocationPage location="ghaziabad"/>}></Route>
          <Route path='/salon-at-home/faridabad' element={<LocationPage location="faridabad"/>}></Route>
          <Route path='/salon-at-home/chandigarh' element={<LocationPage location="chandigarh"/>}></Route>

          
          <Route path='/salon-at-home/indirapuram-ghaziabad' element={<LocationPage location="indirapuram, haziabad"/>}></Route>
          <Route path='/salon-at-home/azadpur-delhi' element={<LocationPage location="azadpur, delhi"/>}></Route>
          <Route path='/salon-at-home/sector-159-noida' element={<LocationPage location="sector-159, noida"/>}></Route>
          <Route path='/salon-at-home/greater-noida-west' element={<LocationPage location="greater noida west"/>}></Route>
          <Route path='/salon-at-home/baldev-nagar-ambala' element={<LocationPage location="baldev nagar, ambala"/>}></Route>

          
          <Route path='/salon-at-home/sector-20-rohini' element={<LocationPage location="sector-20, rohini"/>}></Route>
          <Route path='/salon-at-home/sector-18-noida' element={<LocationPage location="sector-18, noida"/>}></Route>


          {/* <Route path='/salon-at-home/bengaluru' element={<LocationPage location="bengaluru"/>}></Route>
          <Route path='/salon-at-home/hyderabad' element={<LocationPage location="hyderabad"/>}></Route>
          <Route path='/salon-at-home/panaji-goa' element={<LocationPage location="panaji, goa"/>}></Route>
          <Route path='/salon-at-home/nehru-nagar-hyderabad' element={<LocationPage location="nehru nagar, hyderabad"/>}></Route>
          <Route path='/salon-at-home/dehradun-uttarakhand' element={<LocationPage location="dehradun, uttarakhand"/>}></Route>
          <Route path='/salon-at-home/moradabad' element={<LocationPage location="moradabad"/>}></Route>
          <Route path='/salon-at-home/andheri-west-mumbai' element={<LocationPage location="andheri west, mumbai"/>}></Route>
          <Route path='/salon-at-home/madhapur-hyderabad' element={<LocationPage location="madhapur, hyderabad"/>}></Route>
          <Route path='/salon-at-home/alambagh-lucknow' element={<LocationPage location="alambagh, lucknow"/>}></Route>
          <Route path='/salon-at-home/caranzalem-goa' element={<LocationPage location="caranzalem, goa"/>}></Route>
          <Route path='/salon-at-home/bhommanahalli-hongasandra-bengalore' element={<LocationPage location="bhommanahalli hongasandra, bengalore"/>}></Route> */}

          <Route path='/salon-at-home/laxmi-nagar-delhi' element={<LocationPage location="laxmi nagar, delhi"/>}></Route>
          <Route path='/salon-at-home/sector-13-gurgaon' element={<LocationPage location="sector-13, gurgaon"/>}></Route>
          <Route path='/salon-at-home/sector-112-noida' element={<LocationPage location="sector-112, noida"/>}></Route>
          <Route path='/salon-at-home/chattarpur-delhi' element={<LocationPage location="chattarpur, delhi"/>}></Route>
          <Route path='/salon-at-home/sgm-nagar-faridabad' element={<LocationPage location="sgm nagar,faridabad"/>}></Route>
          <Route path='/salon-at-home/mehrauli-gurgaon' element={<LocationPage location="mehrauli, gurgaon"/>}></Route>
          <Route path='/salon-at-home/sector-16-panchkula' element={<LocationPage location="sector-16, panchkula"/>}></Route>
          <Route path='/salon-at-home/sector-17-gurugram' element={<LocationPage location="sector-17, gurugram"/>}></Route>
          <Route path='/salon-at-home/sector-38a-chandigarh' element={<LocationPage location="sector-38a, chandigarh"/>}></Route>
          <Route path='/salon-at-home/bhogal-delhi' element={<LocationPage location="bhogal, delhi"/>}></Route>
          <Route path='/salon-at-home/crossing-republic-ghaziabad' element={<LocationPage location="crossing-republic, ghaziabad"/>}></Route>
          <Route path='/salon-at-home/sector-9a-gurgaon' element={<LocationPage location="sector-9a, gurgaon"/>}></Route>
          <Route path='/salon-at-home/lodhi-colony' element={<LocationPage location="lodhi colony"/>}></Route>
          <Route path='/salon-at-home/burari-delhi' element={<LocationPage location="burari, delhi"/>}></Route>
          <Route path='/salon-at-home/vasundhara-delhi' element={<LocationPage location="vasundhara, delhi"/>}></Route>
          <Route path='/salon-at-home/zirakpur-punjab' element={<LocationPage location="zirakpur, punjab"/>}></Route>
          <Route path='/salon-at-home/aditya-world-city-ghaziabad' element={<LocationPage location="aditya world city, ghaziabad"/>}></Route>
          <Route path='/salon-at-home/sector-23c-chandigarh' element={<LocationPage location="sector-23c, chandigarh"/>}></Route>



{/* ---------------------------------- admin ------------------------------------- */}

          <Route path='/admin' element={<AdminLogin />}></Route>
          <Route path='/admin/home' element={<AdminHomePage />}></Route>
          <Route path='/admin/orders' element={<AdminOrdersPage />}></Route>
          <Route path='/admin/inquiry' element={<AdminInquiryPage/>}></Route>
          <Route path='/admin/contact-us' element={<AdminContactUs/>}></Route>

          
          {/* -------------------- blog main pages ------------------------- */}
          <Route path='/blog/' element={<BlogPage />}></Route>


          {/* -------------------- blog pages ------------------------- */}
          <Route path='/blog/trim-your-pubic-hair' element={<TrimYourPubicHair />}></Route>
          <Route path='/blog/intimate-care' element={<ReasonsWhyYouNeedToManscaped />}></Route>
          <Route path='/blog/mens-grooming' element={<GroomingTips />}></Route>
          <Route path='/grooming-blog.php' element={<GroomingTips />}></Route>
          <Route path='/blog/face-beard-grooming' element={<FaceAndBeardGroomingTips />}></Route>
          <Route path='/face-blog.php' element={<FaceAndBeardGroomingTips />}></Route>
          <Route path='/blog/why-important-to-trim-pubic-hair' element={<WhyIsItImportantToTrimPubicHair />}></Route>
          <Route path='/blog/trea-cracked-heels' element={<TreatCrackedHeels />}></Route>
          <Route path='/blog/treat-cuts-and-scrapes' element={<TreatCutsAndSrapes />}></Route>
          <Route path='/blog/professional-manicure-at-home' element={<ProfessionalManicureAtHome />}></Route>
          <Route path='/blog/shave-without-irritation' element={<ShaveWithoutIrritation />}></Route>
          <Route path='/blog/achieve-glowing-skin-naturally' element={<AchieveGlowingSkinNaturally />}></Route>
          <Route path='/blog/achieve-healthy-hydrated-lips' element={<AchieveHealthyHydratedLips />}></Route>
          <Route path='/blog/salon-quality-nail-art' element={<SalonQualityNailArt />}></Route>
          <Route path='/blog/soft-smooth-hands' element={<SoftSmoothHands />}></Route>
          <Route path='/blog/blackheads-safely' element={<BlackheadsSafely />}></Route>
          <Route path='/blog/hair-thickness-and-volume' element={<HairThicknessAndVolume />}></Route>
          <Route path='/blog/maintain-smooth-skin' element={<MaintainSmoothSkin />}></Route>
          <Route path='/blog/minimize-pain-during-hair-removal' element={<MinimizePainDuringHairRemoval />}></Route>
          <Route path='/blog/athletes-foot' element={<AthletesFoot />}></Route>
          <Route path='/blog/nails-fungal-infections' element={<NailsFungalInfections />}></Route>
          <Route path='/blog/prevent-ingrown-hairs' element={<PreventIngrownHairs />}></Route>
          <Route path='/blog/i-prevent-ingrown-hair' element={<IPreventIngrownHair />}></Route>
          <Route path='/blog/aging-on-my-hands' element={<AgingOnMyHands />}></Route>
          <Route path='/blog/protect-hair-from-chlorine-damage' element={<ProtectHairFromChlorineDamage />}></Route>
          <Route path='/blog/reduce-acne-and-breakouts' element={<ReduceAcneAndBreakouts />}></Route>
          <Route path='/blog/treat-dry-brittle-nails' element={<TreatDryBrittleNails />}></Route>
          <Route path='/blog/care-for-feet-between-pedicure' element={<FeetBetweenPedicure />}></Route>
          <Route path='/blog/care-for-hands-after-gardening' element={<HandsAfterGardening />}></Route>
          <Route path='/blog/skin-after-laser-hair-removal' element={<SkinAfterLaserHairRemoval />}></Route>
          <Route path='/blog/qualified-professional-for-hair-removal-treatments' element={<HairRemovalTreatments />}></Route>
          <Route path='/blog/right-cleanser-for-skin' element={<RightCleanserForSkin />}></Route>
          <Route path='/blog/right-exfoliator-for-pre-waxing' element={<RightExfoliatorForPreWaxing />}></Route>
          <Route path='/blog/right-hand-cream-for-skin-type' element={<RightHandCream />}></Route>
          <Route path='/blog/choose-right-hand-soap-for-sensitive-skin' element={<RightHandSoap />}></Route>
          <Route path='/blog/choose-right-moisturizer' element={<RightMoisturizer />}></Route>
          <Route path='/blog/choose-right-nail-shape-for-hands' element={<RightNailShape />}></Route>
          <Route path='/blog/choose-right-razor' element={<ChooseRightRazor />}></Route>
          <Route path='/blog/choose-right-shampoo-and-conditioner-for-hair' element={<RightShampooAndConditioner />}></Route>
          <Route path='/blog/choose-right-shoes-for-foot-health' element={<ChooseRightShoes />}></Route>
          <Route path='/blog/good-candidate-for-permanent-hair-removal' element={<PermanentHairRemoval />}></Route>
          <Route path='/blog/how-do-establish-nighttime-skincare-routine' element={<NighttimeSkincareRoutine />}></Route>
          <Route path='/blog/how-do-maintain-vibrant-hair-color' element={<VibrantHairColor />}></Route>
          <Route path='/blog/prepare-for-laser-hair-removal-session' element={<PrepareForLaserHairRemoval />}></Route>
          <Route path='/blog/prepare-skin-for-waxing' element={<PrepareSkinForWaxing />}></Route>
          <Route path='/blog/prevent-cold-sores-and-other-lip-infections' element={<LipInfections />}></Route>
          <Route path='/blog/how-do-prevent-nail-polish-from-chipping' element={<PreventNailPolish />}></Route>
          <Route path='/blog/how-do-prevent-premature-aging-of-skin' element={<PrematureAging />}></Route>
          <Route path='/blog/how-do-prevent-split-ends-and-breakage' element={<SplitEnds />}></Route>
          <Route path='/blog/how-do-properly-care-for-sensitive-skin-after-shaving' element={<CareForSensitiveSkin />}></Route>
          <Route path='/blog/how-do-remove-gel-polish-safely-at-home' element={<RemoveGelPolish />}></Route>
          <Route path='/blog/how-do-safely-remove-calluses-at-home' element={<SafelyRemoveCalluses />}></Route>
          <Route path='/blog/soothe-sunburned-skin' element={<SootheSunburnedSkin />}></Route>
          <Route path='/blog/exfoliate-skin' element={<ExfoliateSkin />}></Route>
          <Route path='/blog/how-often-should-i-wash-my-hair' element={<WashMyHair />}></Route>
          <Route path='/blog/how-long-does-male-brazilian-wax-last' element={<MaleBrazilianWax />}></Route>
          <Route path='/blog/what-included-in-mens-brazilian-wax' element={<MenBrazilianWax />}></Route>
          <Route path='/blog/why-do-men-like-brazilian-wax' element={<MenLikeBrazilianWax />}></Route>
          <Route path='/blog/ultimate-guide-to-male-full-body-waxing' element={<FullBodyWaxing />}></Route>
          <Route path='/blog/manicures-and-pedicures-for-men' element={<ManicuresPedicures />}></Route>
          <Route path='/blog/facial-for-men' element={<FacialsForMen />}></Route>
          <Route path='/blog/what-happens-at-male-intimate-waxing' element={<MaleIntimateWaxing />}></Route>
          <Route path='/blog/what-type-of-waxing-is-best-for-men' element={<TypesOfWaxing />}></Route>
          <Route path='/blog/ultimate-guide-to-male-pubic-hair-removal-techniques-and-tips' element={<PubicHairRemoval />}></Route>
          <Route path='/blog/shaving-vs-waxing-which-is-better-for-male-pubic-hair-removal' element={<ShavingVsWaxing />}></Route>
          <Route path='/blog/pros-and-cons-of-popular-male-pubic-hair-removal-methods' element={<PubicHairRemovalMethods />}></Route>
          
          <Route path='/blog/importance-of-body-polish' element={<ImportanceOfBodyPolish />}></Route>
          <Route path='/blog/mens-waxing-services-at-doorstep' element={<WaxingServicesDoorstep />}></Route>
          
          
          {/* -------------------- php pages ------------------------- */}

          <Route path='/intimate-care-blog.php' element={<ReasonsWhyYouNeedToManscaped />}></Route>
          <Route path='/index.php' element={<HomePage />}></Route>
          {/* <Route path='/delhi' element={<LocationPage location="Delhi" />}></Route> */}
          <Route path='/chandigarh' element={<LocationPage location="chandigarh"/>}></Route>
          {/* <Route path='/faridabad' element={<LocationPage location="faridabad"/>}></Route> */}
          {/* <Route path='/noida.php' element={<LocationPage location="noida"/>}></Route> */}
          <Route path='/wedding-groomings' element={<MensPreWeddinGroomingServicesNearMe/>}></Route>
          {/* <Route path='/gurgaon' element={<LocationPage location="gurgaon"/>}></Route> */}
          {/* <Route path='/delhi' element={<LocationPage location="Delhi" />}></Route> */}
          {/* <Route path='/ghaziabad' element={<LocationPage location="ghaziabad"/>}></Route> */}
          {/* <Route path='/noida' element={<LocationPage location="noida"/>}></Route> */}
          <Route path='/bengaluru' element={<LocationPage location="bengaluru"/>}></Route>
          {/* <Route path='/greater-noida' element={<LocationPage location="greater noida"/>}></Route> */}
          <Route path='/pre-wedding-groom.php' element={<MensPreWeddingGroom />}></Route>
          <Route path='/event-party' element={<EventPartyGrooming/>}></Route>
          <Route path='/honey-moon' element={<HoneymoonSpecialGrooming/>}></Route>
          <Route path='/full-body-scrub.php' element={< FullBodyScrub />}></Route>
          <Route path='/zirakpur' element={<LocationPage location="zirakpur"/>}></Route>
          {/* <Route path='/delhi.php' element={<LocationPage location="Delhi" />}></Route> */}
          <Route path='/panchkula' element={<LocationPage location="panchkula"/>}></Route>
          <Route path='/lighten-the-dark-skin.php' element={<LightenTheDarkSkin />}></Route>
          <Route path='/body-therapy.php' element={<   BodyMassagesTherapyAtHome/>}></Route>
          <Route path='/full-body-wax.php' element={<FullBodyWax />}></Route>
          <Route path='/about' element={<AboutPage />}></Route>
          <Route path='/pubic-hair-wax.php' element={<PubicOrBrazilianHairWaxing />}></Route>
          <Route path='/manicure-pedicure.php' element={<  ManicureAndPedicure/>}></Route>
          {/* <Route path='/gurgaon.php' element={<LocationPage location="gurgaon"/>}></Route> */}
          <Route path='/noida-extension' element={<LocationPage location="noida extension"/>}></Route>
          <Route path='/full-body-polishing.php' element={<  FullBodyPolishing />}></Route>
          <Route path='/blog/men-facial.php' element={<MensFacial />}></Route>


          <Route path='/uk/style/grooming/a32052377/best-hair-clippers' element={<HomePage />}></Route>
          <Route path='/chandigrah' element={<LocationPage location="chandigarh"/>}></Route>

          <Route path='/haircut-at-home' element={<HomePage />}></Route>
          <Route path='/service.php' element={<PricePage />}></Route>
          <Route path='/service' element={<PricePage />}></Route>
          <Route path='/body-hair.php' element={<HomePage />}></Route>
          <Route path='/beard-care-at-home.php' element={<HomePage />}></Route>
          <Route path='/beard-care-at-home' element={<HomePage />}></Route>
          <Route path='/haircut-at-home.php' element={<HomePage />}></Route>
          <Route path='/blog/privacy_Policy.php' element={<PrivacyPolicyPage />}></Route>






          






          













          {/* -------------------- services pages ------------------------- */}
          <Route path='/lighten-the-dark-skin' element={<LightenTheDarkSkin />}></Route>
          <Route path='/pre-wedding-groom' element={<MensPreWeddingGroom />}></Route>
          <Route path='/men-facial' element={<MensFacial />}></Route>
          <Route path='/men-facial.php' element={<MensFacial />}></Route>
          <Route path='/full-body-wax' element={<FullBodyWax />}></Route>
          <Route path='/pubic-hair-wax' element={<PubicOrBrazilianHairWaxing />}></Route>
          <Route path='/brazilian-wax' element={<BrazilianWaxing />}></Route>
          <Route path='/full-body-scrub' element={< FullBodyScrub />}></Route>
          <Route path='/full-body-polishing' element={<  FullBodyPolishing />}></Route>
          <Route path='/manicure-pedicure' element={<  ManicureAndPedicure/>}></Route>
          <Route path='/body-therapy' element={<   BodyMassagesTherapyAtHome/>}></Route>
          <Route path='/hair-colour.php' element={<HairColourAtHome/>}></Route>
          <Route path='/hair-colour' element={<HairColourAtHome/>}></Route>
          <Route path='/hair-spa' element={<MensHairSpa/>}></Route>
          <Route path='/meeting-refresh.php' element={<MeetingRefresh/>}></Route>
          <Route path='/meeting-refresh' element={<MeetingRefresh/>}></Route>
          <Route path='/event-party.php' element={<EventPartyGrooming/>}></Route>
          <Route path='/wedding-groomings.php' element={<MensPreWeddinGroomingServicesNearMe/>}></Route>
          <Route path='/honey-moon.php' element={<HoneymoonSpecialGrooming/>}></Route>

          
          {/* <Route path='/other' element={<GMS_PriceList/>}></Route> */}
          {/* <Route path='/other' element={<ViewCartComponent/>}></Route> */}
          
        </Routes>
        <TopButton/>
<ContactButtons/>

      </BrowserRouter>
    </div>
  )
}

export default App