import React from 'react'
import Navbar from '../../components/user/Navbar'
import BreadCrumbComponent from '../../components/user/BreadCrumbComponent'
import FaqServiceComponent from '../../components/user/FaqServiceComponent'
import LocationComponent from '../../components/user/LocationComponent'
import FooterComponent from '../../components/user/FooterComponent'

const FaqPage = () => {
  return (
    <div>
         <div className="home-col-nav">
      <Navbar />
      </div>
        <BreadCrumbComponent name="FAQ" />
        <FaqServiceComponent />
        <LocationComponent/>
        <FooterComponent/>
    </div>
  )
}

export default FaqPage