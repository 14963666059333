import React from 'react'
import Navbar from '../../components/user/Navbar'
import LocationComponent from '../../components/user/LocationComponent'
import PrivacyPolicyComponent from '../../components/user/PrivacyPolicyComponent'
import BreadCrumbComponent from '../../components/user/BreadCrumbComponent'
import LandingFooterComponent from '../../components/user/LandingFooterComponent'

const PrivacyPolicyPage = () => {
  return (
    <div>
        <div className="home-col-nav">
      <Navbar />
      </div>
        <BreadCrumbComponent name="privacy policy" />
<PrivacyPolicyComponent/>
        <LocationComponent/>
        {/* <FooterComponent/> */}
        <LandingFooterComponent />    
        
    </div>
  )
}

export default PrivacyPolicyPage