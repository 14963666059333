import React from 'react'
import Navbar from '../../components/user/Navbar'
import BreadCrumbComponent from '../../components/user/BreadCrumbComponent'
import LocationComponent from '../../components/user/LocationComponent'
import FooterComponent from '../../components/user/FooterComponent'
import GalleryPageComponent from '../../components/user/GalleryPageComponent'
import { Helmet } from "react-helmet";


const GalleryPage = () => {
  return (
    <div>


<Helmet>
        <title>Glazma - Gallery : Men's Grooming And Waxing Service.
        </title>
        <meta
          name="description"
          content="
          Men's Grooming and Waxing Service at Home in Noida, Delhi NCR. Get best men's grooming and waxing service by professional groomers.
         "
        />
      </Helmet>
      
      
      <div className="home-col-nav">
      <Navbar />
      </div>
      
        <BreadCrumbComponent name="gallery"/>
<GalleryPageComponent/>
        <LocationComponent/>
        <FooterComponent/>
    </div>
  )
}

export default GalleryPage