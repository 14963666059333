import React from 'react'
import Navbar from '../../components/user/Navbar'
import BreadCrumbComponent from '../../components/user/BreadCrumbComponent'
import PricePageComponent from '../../components/user/PricePageComponent'
import LocationComponent from '../../components/user/LocationComponent'
import FooterComponent from '../../components/user/FooterComponent'
import { Helmet } from "react-helmet";

const PricePage = () => {
  return (
    <div>

<Helmet>
        <title>Glazma : Mens Grooming and Waxing Service Price List</title>
        <meta
          name="description"
          content="
          Glazma offers doorstep men's grooming and waxing services at lowest price in Noida, Delhi NCR. Book men's grooming and waxing services at hone.
          
         "
        />
      </Helmet>
       <div className="home-col-nav">
      <Navbar />
      </div>
        <BreadCrumbComponent name="price list"/>
        <PricePageComponent/>
        <LocationComponent/>
        <FooterComponent/>
    </div>
  )
}

export default PricePage