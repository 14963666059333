import React, { useState } from "react";
import priceListData from "./PriceList.json";
import priceListWaxData from "./PriceListNcr.json";
import priceListCategoryData from "./PriceListCategoryNcr.json";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, removeItem } from "../../store/cartSlice";

const LandingPriceNcrPagePriceComponent = () => {
  const [viewType, setViewType] = useState("NORMAL");

  // -----------------------------------

  const dispatch = useDispatch();

  const { cart } = useSelector(
    (state) => state.allCart
  );

  return (
    <div>
      <div className="price-page">
        <div>
          <h1 className="price-page-heading">GLAZMA MEN'S GROOMING SERVICES</h1>

          <div className="price-page-content">
            {/* -------------------- list start ------------------ */}

            <div className="price-page-content-col">
              <h2 className="price-page-content-col-heading">
                GLAZMA MEN'S WAXING
              </h2>

              <div className="landing-list-price-filter">
                <div
                  onClick={() => setViewType("NORMAL")}
                  className={
                    viewType === "NORMAL"
                      ? "landing-list-price-filter-option-active"
                      : ""
                  }
                >
                  NORMAL <span>WAX</span>
                </div>
                <div
                  onClick={() => setViewType("RICA")}
                  className={
                    viewType === "RICA"
                      ? "landing-list-price-filter-option-active"
                      : ""
                  }
                >
                  RICA <span>WAX</span>
                </div>
                {/* <div onClick={()=>setViewType("RAAGA")} className={viewType === "RAAGA" ? "landing-list-price-filter-option-active" :""}>RAAGA <span>WAX</span></div> */}
                <div
                  onClick={() => setViewType("PEEL")}
                  className={
                    viewType === "PEEL"
                      ? "landing-list-price-filter-option-active"
                      : ""
                  }
                >
                  PEEL OFF <span>WAX</span>
                </div>
              </div>

              <div className="price-page-content-col-list">
                {priceListWaxData
                  .filter((e) => e.type === viewType)
                  .map((e) => (
                    <div className="price-page-content-col-list-col">
                      <div className="price-page-content-col-list-head">
                        <div className="price-page-content-col-list-heading">
                          {e.name}
                        </div>
                        <div className="price-page-content-col-list-price">
                          <s> Rs. {e.mainPrice}</s> {e.price}/-
                        </div>
                      </div>

                      <div className="price-page-content-col-list-button">
                        {cart.find((a) => a._id === e._id) ? (
                          <div>Added</div>
                        ) : (
                          <span onClick={() => dispatch(addToCart(e))}>
                            Add
                          </span>
                        )}
                      </div>

                      {/* <a href="#book-now" className="landing-page-web-get">Get</a>
                        <a href="#book-appointment" className="landing-page-mobile-get">Get</a> */}
                    </div>
                  ))}
              </div>
            </div>
            <br />

            {/* --------------------------------- */}

            {/* -------------------- list end ------------------ */}
          </div>

          {/* ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
          {/* ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}
          {/* ---------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- */}

          <div className="price-page-content">
            {/* -------------------- list start ------------------ */}

            {priceListCategoryData.map((data) => (
              <div className="price-page-content-col">
                <h2 key={data._id} className="price-page-content-col-heading">
                  {data.priceListCategoryName}
                </h2>
                <div className="price-page-content-col-list">
                  {priceListData
                    .filter(
                      (item) =>
                        item.priceListCategoryName ===
                        data.priceListCategoryName
                    )
                    .filter(
                      (e) =>
                        e.name !== "HEAD MASSAGE (20 MNTS)" &&
                        e.name !== "FOOT MASSAGE (20 MNTS)"
                    )
                    .map((e) => (
                      <div className="price-page-content-col-list-col">
                        <div className="price-page-content-col-list-head">
                          <div className="price-page-content-col-list-heading">
                            {e.name}
                          </div>
                          <div className="price-page-content-col-list-price">
                            <s> Rs. {e.mainPrice}</s> {e.price}/-
                          </div>
                        </div>

                        <div className="price-page-content-col-list-button">
                          {cart.find((a) => a._id === e._id) ? (
                            // <div onClick={() => dispatch(removeItem(e._id))}><i class="fa-solid fa-trash"></i></div>
                            // <div><i class="fa-solid fa-circle-check"></i></div>
                            // <div><i class="fa-solid fa-check"></i></div>
                            <div>Added</div>
                          ) : (
                            <span onClick={() => dispatch(addToCart(e))}>
                              Add
                            </span>
                          )}
                        </div>

                        {/* <a href="#book-now" className="landing-page-web-get">Get</a>
                        <a href="#book-appointment" className="landing-page-mobile-get">Get</a> */}
                      </div>
                    ))}
                </div>
              </div>
            ))}

            {/* --------------------------------- */}

            {/* -------------------- list end ------------------ */}
          </div>

          <div id="packages">
            {/* <a
              href="#services"
              className="landing-page-button-web-yellow landing-page-button-black "
            >
              Book Your Waxing Now
            </a>

            <a
              href="#book-appointment"
              className="landing-page-button-mobile-yellow landing-page-button-black"
            >
              Book Your Waxing Now
            </a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPriceNcrPagePriceComponent;
