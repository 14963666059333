import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LoadingComponent from "./LoadingComponent";

const ConfirmBookingForm = ({ setConfirmForm, crt }) => {
  // const { cart, totalQuantity, totalPrice, allNames } = useSelector(
  const { cart, totalQuantity, totalPrice } = useSelector(
    (state) => state.allCart
  );

  const services = cart.map(e=>(e.fullName))
  
  // ------------------------------------------------
  const [loading, setLoading] = useState(false);

  // post ----------------------------------------------------------

  const pageFullUrl = window.location.href;

  const navigate = useNavigate();

  const [user, setUser] = useState({
    pageUrl : pageFullUrl,
    cartData: cart,
    servicesName: services,
    totalServices: cart.length,
    servicesQuantity: totalQuantity,
    totalPrice: totalPrice,
    name: "",
    phone: "",
    address: "",
    user: "true",
    status: "true",
  });

  const handleChange = (e) => {
    const value = e.target.value;
    
      
      setUser({
        ...user,
        [e.target.name]: value,
      });
    
  };

  
  const handlePhone = (e) => {
    const value = e.target.value;
    if(value.length <= 10){

      setUser({
        ...user,
        phone: value,
      });
    }
  };


  const handleSubmit = async (e) => {
    setLoading(true);

    e.preventDefault();
    try {
      // const response = await fetch("http://localhost:8000/booking-form", {
         const response = await fetch("https://api.shop.glazma.com/booking-form", {
        method: "POST", 
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      });

      if (response.ok) {
       
        navigate("/thank-you");
        window.location.reload();
      } else {
        const res_data = await response.json();
        alert(res_data.message);
      }
    } catch (error) {
      alert("server error");
    }
    setLoading(false);
  };

  console.log("services = ", services)
  

  return (
    <div>
      {loading ? (
        <div className="data-loading">
          <LoadingComponent />
        </div>
      ) : (
        ""
      )}

      <div className="confirm-booking">
        <div>
          <div className="confirm-booking-close-btn">
            <i
              className="fa-solid fa-circle-xmark"
              onClick={(e) => setConfirmForm(false)}
            ></i>
          </div>
          <div className="confirm-booking-heading">Confirm Booking</div>

          <div className="confirm-booking-form">
            <form onSubmit={handleSubmit}>
              <div>
                <label>Name</label>
                <input
                  required
                  type="text"
                  placeholder="enter your name"
                  name="name"
                  onChange={handleChange}
                />
              </div>

              <div>
                <label>Number</label>
                <input
                  required
                  type="number"
                  placeholder="enter your number"
                  name="phone"
                  onChange={handlePhone}
                  value={user.phone}
                />
              </div>

              <div>
                <label>Address/city (optional)</label>
                <textarea
                  // required
                  type="text"
                  placeholder="enter your address or city"
                  name="address"
                  onChange={handleChange}
                />
              </div>

              <button>Book Now</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmBookingForm;
