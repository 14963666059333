import React, { useRef } from "react";
import logo from "../../media/images/glazma-logo.png";
import { Link } from "react-router-dom";

const LandingNavbar = () => {
  const navRef = useRef();

  const showNavbar = () => {
    navRef.current.classList.toggle("responsive_nav");
  };

  return (
    <div>
    <div className="navbar-main">
    <div className="navbar">
      <div className="nav-logo">
        <a href="#why-us" >
          <img src={logo} alt="logo" />
        </a>
      </div>

      <div className="nav-menu" ref={navRef}>
        <a onClick={showNavbar} href="#services">SERVICES</a>
        <a onClick={showNavbar} href="#packages">PACKAGES</a>
        <a onClick={showNavbar} href="#how-we-deliver">HOW WE DELIVER</a>
        <a onClick={showNavbar} href="#why-us">WHY US</a>
        <a onClick={showNavbar} href="#testimonial">TESTIMONIALS</a>
        <a onClick={showNavbar} href="#faqs">FAQ</a>
        

        <button className="nav-btn nav-close-btn" onClick={showNavbar}>
          <i className="fa-solid fa-circle-xmark"></i>
        </button>
      </div>
      
      <button className="nav-btn menu-btn landing-nav-col">
        <Link to="tel:+919658153153" target="blank"><i class="fa-solid fa-phone"></i>+91 9658153153</Link>
        <i className="fa-solid fa-bars"  onClick={showNavbar}></i>
      </button>
    </div>
    </div>
  </div>
  )
}

export default LandingNavbar