import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";

import {
  getCartTotal,
  removeItem,
  decreaseItemQuantity,
  increaseItemQuantity,
} from "../../store/cartSlice";
import ConfirmBookingForm from "./ConfirmBookingForm";
import ViewCartComponentOld from "../cart/ViewCartComponentOld";
import ViewCartComponent from "../cart/ViewCartComponent";

const CartComponent = () => {
  const [cartList, setCartList] = useState(false);

  const [confirmForm, setConfirmForm] = useState(false);

  // --------------------------------------------------------------------------------------------

  const { cart, totalQuantity, totalPrice } = useSelector(
    (state) => state.allCart
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCartTotal());
    setCartList(true)
  }, [cart]);

  // console.log("first", cart)

  return (
    <div>
      {confirmForm ? (
        <ConfirmBookingForm setConfirmForm={setConfirmForm} crt={cart} />
      ) : (
        ""
      )}
      {cart.length <= 0 ? (
        ""
      ) : (
        <div className="cart-fix-col">
          <div
            className="cart-fix-col-text"
            onClick={(e) => setCartList(!cartList)}
          >
            Total Amount : <span> Rs. {totalPrice} /-</span>
          </div>
          <div
            className="cart-fix-button"
            onClick={(e) => setCartList(!cartList)}
          >
            <div>{cart.length}</div>
            {/* {totalQuantity} */}
            <i className="fa-solid fa-cart-shopping"></i>
          </div>
        </div>
      )}

      {/* ------------------------------------------------------------- */}

      {
      // cartList ? 
      cart.length > 0 && cartList ?
      (
        //  <ViewCartComponentOld setCartList={setCartList} cartList={cartList} setConfirmForm={setConfirmForm}/>
        <ViewCartComponent
          setCartList={setCartList}
          // cartList={cartList}
          setConfirmForm={setConfirmForm}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default CartComponent;
