import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';

import {
  getCartTotal,
  removeItem,
  decreaseItemQuantity,
  increaseItemQuantity,
} from "../../store/cartSlice";

const ViewCartComponent = ({setCartList, setConfirmForm}) => {
  const handleClose = () => {
        setCartList(false)

  };

  
    
    const { cart, totalQuantity, totalPrice } = useSelector(
      (state) => state.allCart
    );
  
    const dispatch = useDispatch();
  
    useEffect(() => {
      dispatch(getCartTotal());
    }, [cart]);

  const minAmount = 1199;
//   const totalPrice = 1099;

  return (
    <div>
      <div className="view-cart">
        <div className="view-cart-close" onClick={handleClose}></div>

        <div className="view-cart-main">
          <div className="view-cart-top">
            <div></div>
            <div>MY CART</div>
            <span onClick={handleClose}>CLOSE</span>
          </div>

          <div className="view-cart-content">

            {
                cart?.map( e => (
            <div className="view-cart-list">
              <div className="view-cart-head">
                <div>{e.fullName}</div>
                <span>
                  <i onClick={() => dispatch(removeItem(e._id))} class="fa-solid fa-trash-can"></i>
                </span>
              </div>
              <div className="view-cart-value">
                <div className="view-cart-quantity">
                    {
                        e.quantity <=1?
                  <div className="view-cart-quantity-disable">-</div>
                  :
                  <div className="view-cart-quantity" onClick={() =>
                                                      dispatch(decreaseItemQuantity(e._id))
                                                    }>-</div>
                    }
                  <span>{e.quantity}</span>
                  {
                    e.quantity <=9?
                  
                  <div  onClick={() =>
                                                    dispatch(increaseItemQuantity(e._id))
                                                  }>+</div>
                                                  :
                  <div className="view-cart-quantity-disable">+</div>

                                                }
                </div>
                <div className="view-cart-price">₹ {e.price}</div>
              </div>
            </div>

                ))

            }

          
          </div>

          <div className="view-cart-end">

            {
                totalPrice<minAmount?

                        <div className="view-cart-limit">
              <div className="view-cart-limit-text">Add services worth <b>₹{minAmount-totalPrice}</b> to complete order</div>
              <div className="view-cart-limit-bar">
                <div
                  style={{ width: `${(totalPrice / minAmount) * 100}%` }}
                ></div>
              </div>
              <div className="view-cart-limit-head">
                <div>Salon For Men</div>
                <div>₹{totalPrice} / ₹{minAmount}</div>
              </div>
            </div>
            :""}

            <div className="view-cart-total">
              <span>Cart Subtotal :</span>
              <div>₹ {totalPrice}</div>
            </div>

            {
                totalPrice<minAmount?
                <div className="view-cart-button-disable">Proceed to Checkout</div>
                :
            <div className="view-cart-button"  onClick={e=> setConfirmForm(true)}>Proceed to Checkout</div>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewCartComponent;
