import React from "react";
import Navbar from "../../components/user/Navbar";
import LocationComponent from "../../components/user/LocationComponent";
import FooterComponent from "../../components/user/FooterComponent";
import ContactComponent from "../../components/user/ContactComponent";
import BreadCrumbComponent from "../../components/user/BreadCrumbComponent";
import { Helmet } from "react-helmet";

const ContactPage = () => {
  return (
    <div>

      
<Helmet>
        <title>Contact Glazma : Mens Grooming and Waxing Service At Doorstep.</title>
        <meta
          name="description"
          content="
          Men's Grooming and waxing Service at Home in Noida, Delhi NCR: Get best men's grooming and waxing service by experts at your location.
         "
        />
      </Helmet>
      
      
      <div className="home-col-nav">
      <Navbar />
      </div>
      <BreadCrumbComponent name="contact us"/>
<ContactComponent/>
      <LocationComponent />
      <FooterComponent />
    </div>
  );
};

export default ContactPage;
