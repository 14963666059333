import React from 'react'
import Navbar from '../../components/user/Navbar'
import HomePackageComponent from '../../components/user/HomePackageComponent'
import LocationComponent from '../../components/user/LocationComponent'
import FooterComponent from '../../components/user/FooterComponent'
import BreadCrumbComponent from '../../components/user/BreadCrumbComponent'
import { Helmet } from "react-helmet";


const PackagesPage = () => {
  return (
    <div>

      
<Helmet>
        <title>Glazma : Mens Grooming and Waxing Service Packages.</title>
        <meta
          name="description"
          content="
          Glazma offers doorstep men's grooming and waxing services at lowest price in Noida, Delhi NCR. Book men's grooming and waxing services at hone.
          
         "
        />
      </Helmet>
      
      <div className="home-col-nav">
      <Navbar />
      </div>
        <BreadCrumbComponent name="packages"/>
        <HomePackageComponent h1/>
        <LocationComponent/>
        <FooterComponent/>
    </div>
  )
}

export default PackagesPage