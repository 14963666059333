import React, { useEffect, useRef, useState } from "react";
import { TypeAnimation } from "react-type-animation";
import LoadingComponent from "./LoadingComponent";
import { useNavigate } from "react-router-dom";

const LandingHeaderNCR = ({location}) => {
  

  // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::

    const [isVisible, setIsVisible] = useState(false);
    const formRef = useRef(null);
  
    useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);  // Text is visible, trigger animation
        } else {
          setIsVisible(false); // Text is not visible, reset animation
        }
      }, { threshold: 0.5 });
  
      if (formRef.current) {
        observer.observe(formRef.current);
      }
  
      return () => {
        if (formRef.current) {
          observer.unobserve(formRef.current);
        }
      };
    }, []);
  

  // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  // :::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::
  
  
  
  
  const [loading, setLoading] = useState(false);
  const [numberError, setNumberError] = useState()
  
  const [data, setData] = useState({
    name : "",
    number : "",
    email : "",
    location : location,
    message : "",
    date : "",
  })

  const handleChange = (e) => {
    setNumberError("")
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const navigate = useNavigate();


  const handleSubmit = async (e) => {
    setLoading(true);

    e.preventDefault();

    if(data.number.length === 10){
          try {
            const response = await fetch("https://api.shop.glazma.com/landing-page-form", {
            // const response = await fetch("http://localhost:8000/landing-page-form", {
              method: "POST", 
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            });
      
            console.log(response)
      
            if (response.ok) {
              navigate("/thank-you");
            } else {
              const res_data = await response.json();
              alert(res_data.message);
            }
          } catch (error) {
            alert("server error");
            console.log("error is :", error)
          }
    }
    else{
      setNumberError("Enter the valid phone number");
    }
    setLoading(false);
  };
  
  
  return (
    <div>

{loading ? (
        <div className="data-loading">
          <LoadingComponent />
        </div>
      ) : (
        ""
      )}
      
      <div className="landing-header">
        <div className="landing-header-main">
          <div className="landing-header-content">
            <div className="landing-header-heading-1">
              <TypeAnimation
                sequence={[
                  "lighten the dark skin",
                  2500,
                  "Mens waxing service",
                  2500,
                  "groom packages",
                  2500,
                  "facials",
                  2500,
                  "full body waxing for men",
                  2500,
                  "DELICATE AREAS wax",
                  2500,
                  "Specialized Waxing",
                  2500,
                  "full body scrub",
                  2500,
                  "Body waxing for men",
                  2500,
                  "full body polishing",
                  2500,
                  "manicure & pedicure",
                  2500,
                  "men waxing services",
                  2500,
                  "Men's body hair trimming service",
                  2500,
                  "full body waxing services",
                  2500,
                  "waxing services for men at home",
                  2500,
                  "male brazilian",
                  2500,
                  "men waxing at home",
                  2500,
                  "mens full body wax",
                  2500,
                  "private part hair removal for men",
                  2500,
                  "full body wax",
                  2500,
                ]}
                speed={50}
                repeat={Infinity}
              />
            </div>

            <div className="landing-header-heading-1">in <div>{location&&location}</div></div>
            <div className="landing-header-heading-2">
            waxing services for men at home
            </div>
            <div id="book-appointment" ></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingHeaderNCR;
