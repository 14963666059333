import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import NavTop2Component from "../../components/user/NavTop2Component";
import LandingNavbar from "../../components/user/LandingNavbar";
import LandingHeader from "../../components/user/LandingHeader";
import NumberIncrementComponent from "../../components/user/NumberIncrementComponent";
import LandingWhyManscapeComponent from "../../components/user/LandingWhyManscapeComponent";
import LandingVideoComponent from "../../components/user/LandingVideoComponent";
import LandingPageWhyUs from "../../components/user/LandingPageWhyUs";
import LandingTestimonialComponent from "../../components/user/LandingTestimonialComponent";
import FaqComponent from "../../components/user/FaqComponent";
import LandingLocationComponent from "../../components/user/LandingLocationComponent";
import LandingFooterComponent from "../../components/user/LandingFooterComponent";
import ContactButtons from "../../components/user/ContactButtons";
import LandingPackageComponent from "../../components/user/LandingPackageComponent";
import LandingPriceNcrPagePriceComponent from "../../components/user/LandingPriceNcrPagePriceComponent";
import LandingHeaderNCR from "../../components/user/LandingHeaderNCR";

const LandingPageNcr = ({ location }) => {
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, []);

  return (
    <div>
      <Helmet>
        <title>Male beauty and waxing service at home in {location}</title>

        <meta
          name="description"
          content={`
          Book Glazma online male beauty and waxing service at home in ${location}. Get best men's grooming and waxing service by professional groomers.
          `}
        />

        <meta
          name="keywords"
          content={`
          Men's grooming home service
          `}
        />
      </Helmet>

      <div className="landing-page landing-page-ncr">
        <div id="book-now" className="landing-web-target"></div>
        <div className="landing-page-navbar">
          <NavTop2Component />
          <LandingNavbar />
        </div>
        <LandingHeaderNCR location={location} />

        <NumberIncrementComponent />
        <LandingWhyManscapeComponent />

        <div>
          <LandingPriceNcrPagePriceComponent />
        </div>

        <div>
          <LandingPackageComponent />
        </div>
        
        <LandingVideoComponent />

        <div>
          <LandingPageWhyUs />
        </div>

        <div>
          <LandingTestimonialComponent />
        </div>

        <div>
          <FaqComponent />
        </div>

        <LandingLocationComponent />

        <LandingFooterComponent />
      </div>
      <ContactButtons />
    </div>
  );
};

export default LandingPageNcr;
