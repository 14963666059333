import React, { useEffect } from "react";
import LandingNavbar from "../../components/user/LandingNavbar";
import LandingHeader from "../../components/user/LandingHeader";
import NumberIncrementComponent from "../../components/user/NumberIncrementComponent";
import WhyGlazma from "../../components/user/WhyGlazma";
import NavTop2Component from "../../components/user/NavTop2Component";
import LandingWhyManscapeComponent from "../../components/user/LandingWhyManscapeComponent";
import LandingTestimonialComponent from "../../components/user/LandingTestimonialComponent";
import LandingLocationComponent from "../../components/user/LandingLocationComponent";
import LandingFooterComponent from "../../components/user/LandingFooterComponent";
import FaqComponent from "../../components/user/FaqComponent";
import LandingPricePageComponent from "../../components/user/LandingPricePageComponent";
import ContactButtons from "../../components/user/ContactButtons";
import { Helmet } from "react-helmet";
import LandingPackageComponent from "../../components/user/LandingPackageComponent";
import LandingPageWhyUs from "../../components/user/LandingPageWhyUs";
import LandingVideoComponent from "../../components/user/LandingVideoComponent";
import GMS_PriceList from "../../components/price/GMS_PriceList";
import PricePageComponent from "../../components/user/PricePageComponent";
import HomePackageComponent from "../../components/user/HomePackageComponent";
import LocationComponent from "../../components/user/LocationComponent";

const LandingPage = ({location}) => {

  useEffect(() => {
    // Check if the URL has a hash and scroll to the element with that id
    const hash = window.location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);
  
  return (
    <div>

      
<Helmet>
        <title>
          Male beauty and waxing service at home in {location} 

        </title>
        
        <meta
          name="description"
          content={`
          Book Glazma online male beauty and waxing service at home in ${location}. Get best men's grooming and waxing service by professional groomers.
          `}
          />
          
        <meta
          name="keywords"
          content={`
          Men's grooming home service
          `}
          />
          
      </Helmet>
      
      
      <div className="landing-page">

      <div id="book-now" className="landing-web-target"></div>
      <div className="landing-page-navbar">
      <NavTop2Component/>
        <LandingNavbar />

      </div >
        <LandingHeader location={location} />

        <NumberIncrementComponent />
        <LandingWhyManscapeComponent />
        
        {/* <GMS_PriceList/>  */}
        
        <div>
          {/* <LandingPricePageComponent /> */}
          <PricePageComponent />
        </div>
        
        <div>
        <HomePackageComponent />
        {/* <LandingPackageComponent /> */}
        </div>
        <LandingVideoComponent />
        
        <div>
        <LandingPageWhyUs />    
        </div>
        
        <div>
        <LandingTestimonialComponent />    
        </div>
        
        <div>
      <FaqComponent /> 
        </div>

        {/* <LandingLocationComponent />     */}
        <LocationComponent />    

        <LandingFooterComponent />    

      </div>
              <ContactButtons/>
      
    </div>
  );
};

export default LandingPage;
